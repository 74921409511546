"use client";

import React from "react";
import CommonErrorPage from "@koble/ui/src/CommonErrorPage";

const Error = ({ error }: { error: Error & { digest?: string } }) => (
  <CommonErrorPage error={error} />
);

export default Error;

"use client";

import { ReactNode } from "react";
import { Space, Typography } from "antd";

const { Text, Title } = Typography;
const CommonEmptyContainer = ({
  title,
  subtitle,
  img,
  alt,
  extraContent,
}: {
  title: string;
  subtitle: string | ReactNode;
  img: string;
  alt: string;
  extraContent?: ReactNode;
}) => {
  return (
    <Space
      direction="vertical"
      size={16}
      style={{
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <img src={img} alt={alt} />
      <Title level={4}>{title}</Title>
      <div style={{ maxWidth: 400 }}>
        <Text>{subtitle}</Text>
      </div>
      {extraContent}
    </Space>
  );
};

export default CommonEmptyContainer;
